var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-async-select", {
    staticClass: "offer-filter",
    attrs: {
      id: "offer-select",
      name: "select-offer",
      "add-hex-color": "orange",
      value: _vm.value,
      "class-input": "pt-0 shadow-none rounded-none multiselect",
      "text-error": _vm.textError,
      error: _vm.error,
      "items-per-page": 10,
      "disable-selected-options": _vm.disableSelectedOptions,
      required: _vm.required,
      service: _vm.apiService,
    },
    on: { change: _vm.emitSelection },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }