<template>
  <asterix-async-select
    id="offer-select"
    name="select-offer"
    class="offer-filter"
    add-hex-color="orange"
    :value="value"
    class-input="pt-0 shadow-none rounded-none multiselect"
    :text-error="textError"
    :error="error"
    :items-per-page="10"
    :disable-selected-options="disableSelectedOptions"
    :required="required"
    :service="apiService"
    @change="emitSelection"
  />
</template>

<script>
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export default {
  name: 'OfferFilter',
  components: {
    AsterixAsyncSelect,
  },
  props: {
    service: {
      type: Function,
      required: true,
    },
    textError: {
      type: String,
      default: () => '',
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [Object, Array],
      default: () => [],
    },
    disableSelectedOptions: {
      type: Boolean,
      default: () => false,
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  computed: {},
  methods: {
    apiService(params = new QueryParamsBuilder()) {
      params.addFilter('client.id', this.clientId);
      return this.service(params);
    },
    emitSelection({ items }) {
      if (items) {
        items.value = items.id;
      }
      this.$emit('input', items);
      this.$emit('change', items);
    },
  },
};
</script>

<style scoped>
.offer-filter {
  width: 330px;
}
</style>
